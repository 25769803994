import { Getter } from "vuex";
import { IState } from './state';

const event: Getter<IState, IState> = (state) => state.event;
const translation: Getter<IState, IState> = (state) => state.translation;

export default {
	event,
	translation
}
