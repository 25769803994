export interface IState
{
	event: IEvent,
	translation: Object
}

export interface IEvent
{
	dates: string[],
	location: string,
	heading: string,
	preamble: string,
	url: string,
	full: boolean
}

const deafultState: IState = {
	event: {
		dates: [],
		location: '',
		heading: '',
		preamble: '',
		url: '',
		full: false
	},
	translation: {}
}

export default deafultState;
