



























import Vue from 'vue'
import { IEvent } from '../store/state'

interface CardComputed {
	event: IEvent,
	translation: Object
}

export default Vue.extend<{}, {}, CardComputed, {}>({
	name: 'card',
	computed: {
		event() { return this.$store.getters.event },
		translation() { return this.$store.getters.translation }
	}
})
